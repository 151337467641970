exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-candidatura-enviada-js": () => import("./../../../src/pages/candidatura-enviada.js" /* webpackChunkName: "component---src-pages-candidatura-enviada-js" */),
  "component---src-pages-cat-js": () => import("./../../../src/pages/cat.js" /* webpackChunkName: "component---src-pages-cat-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-cultura-js": () => import("./../../../src/pages/cultura.js" /* webpackChunkName: "component---src-pages-cultura-js" */),
  "component---src-pages-devops-consulting-js": () => import("./../../../src/pages/devops-consulting.js" /* webpackChunkName: "component---src-pages-devops-consulting-js" */),
  "component---src-pages-easy-cloud-and-applications-js": () => import("./../../../src/pages/easy-cloud-and-applications.js" /* webpackChunkName: "component---src-pages-easy-cloud-and-applications-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-old-index-js": () => import("./../../../src/pages/old-index.js" /* webpackChunkName: "component---src-pages-old-index-js" */),
  "component---src-pages-politica-de-privacidade-js": () => import("./../../../src/pages/politica-de-privacidade.js" /* webpackChunkName: "component---src-pages-politica-de-privacidade-js" */),
  "component---src-pages-sobre-nos-js": () => import("./../../../src/pages/sobre-nos.js" /* webpackChunkName: "component---src-pages-sobre-nos-js" */),
  "component---src-pages-solucoes-js": () => import("./../../../src/pages/solucoes.js" /* webpackChunkName: "component---src-pages-solucoes-js" */),
  "component---src-pages-sucesso-js": () => import("./../../../src/pages/sucesso.js" /* webpackChunkName: "component---src-pages-sucesso-js" */),
  "component---src-pages-vaga-candidatar-js": () => import("./../../../src/pages/vaga-candidatar.js" /* webpackChunkName: "component---src-pages-vaga-candidatar-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-cases-list-js": () => import("./../../../src/templates/cases-list.js" /* webpackChunkName: "component---src-templates-cases-list-js" */),
  "component---src-templates-cases-post-js": () => import("./../../../src/templates/cases-post.js" /* webpackChunkName: "component---src-templates-cases-post-js" */),
  "component---src-templates-category-list-js": () => import("./../../../src/templates/category-list.js" /* webpackChunkName: "component---src-templates-category-list-js" */),
  "component---src-templates-jobs-list-js": () => import("./../../../src/templates/jobs-list.js" /* webpackChunkName: "component---src-templates-jobs-list-js" */),
  "component---src-templates-jobs-post-js": () => import("./../../../src/templates/jobs-post.js" /* webpackChunkName: "component---src-templates-jobs-post-js" */)
}

